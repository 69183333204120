html, body, div#root {
  height: 100%; }

div#root {
  display: flex;
  flex-direction: column;

  div#main {
    flex-grow: 1; } }

.print-only {
  display: none; }

@media print {
  .no-print, .no-print * {
    display: none !important; }

  .print-only {
    display: block; }

  div.no-break {
    break-inside: avoid; } }
