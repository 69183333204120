$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1140px);

$primary: #f2d200;
$link-color: #0065c8;
$btn-link-color: $link-color;
$input-bg: #ffffff;

$body-bg: #f0f2f5;
$body-color: #000000;

$font-weight-bold: 600;

@import '~bootstrap/scss/bootstrap.scss';

::selection {
  background: $primary; }
::-moz-selection {
  background: $primary; }
